import * as React from 'react'
import Seo from '../components/seo'
import * as Styles from './cookie.module.sass'

const Cookie = () => {
  return (
    <div className={`container ${Styles.main}`}>
      <Seo
        title={`Уведомление об использовании cookie файлов`}
      />
      <p>&nbsp;</p>
      <h1>Уведомление о использовании cookie файлов</h1>
      <p>&nbsp;</p>
      <p>Наш сайт, как и&nbsp;большинство других, использует файлы cookie и&nbsp;другие похожие технологии (пиксельные тэги и&nbsp;т.&nbsp;п.), чтобы предоставлять услуги, наиболее отвечающие Вашим интересам и&nbsp;потребностям, а&nbsp;также собирать статистическую и&nbsp;маркетинговую информацию для анализа и&nbsp;совершенствования наших услуг и&nbsp;сайтов.</p>
      <p>При использовании данного сайта, вы&nbsp;подтверждаете свое согласие на&nbsp;использование файлов cookie и&nbsp;других похожих технологий в&nbsp;соответствии с&nbsp;настоящим Уведомлением.</p>
      <p>Если Вы&nbsp;не&nbsp;согласны, чтобы мы&nbsp;использовали данный тип файлов, Вы&nbsp;должны соответствующим образом установить настройки Вашего браузера или не&nbsp;использовать наш сайт.</p>
      <p>Обращаем Ваше внимание на&nbsp;то, что при блокировании или удалении cookie файлов, мы&nbsp;не&nbsp;можем гарантировать корректную работу нашего сайта в&nbsp;Вашем браузере.</p>
      <p>Cookie файлы, которые сохраняются через веб-сайт, не&nbsp;содержат сведений, на&nbsp;основании которых можно Вас идентифицировать.</p>
      <p>&nbsp;</p>
      <h2>Что такое файл cookie и&nbsp;другие похожие технологии</h2>
      <p>&nbsp;</p>
      <p>Файл cookie представляет собой небольшой текстовый файл, сохраняемый на&nbsp;вашем компьютере, смартфоне или другом устройстве, которое Вы&nbsp;используете для посещения интернет-сайтов.</p>
      <p>Некоторые посещаемые Вами страницы могут также собирать информацию, используя пиксельные тэги и&nbsp;веб-маяки, представляющие собой электронные изображения, называемые одно-пиксельными (1&times;1) или пустыми GIF-изображениями.</p>
      <p>Файлы cookie могут размещаться на&nbsp;вашем устройстве нами (&laquo;собственные&raquo; файлы cookie) или другими операторами (файлы cookie &laquo;третьих лиц&raquo;).</p>
      <p>Мы&nbsp;используем два вида файлов cookie на&nbsp;сайте: &laquo;cookie сессии&raquo; и&nbsp;&laquo;постоянные cookie&raquo;. Cookie сессии&nbsp;&mdash; это временные файлы, которые остаются на&nbsp;устройстве пока вы&nbsp;не&nbsp;покинете сайт. Постоянные cookie остаются на&nbsp;устройстве в&nbsp;течение длительного времени или пока вы&nbsp;вручную не&nbsp;удалите их&nbsp;(как долго cookie останется на&nbsp;вашем устройстве будет зависеть от&nbsp;продолжительности или &laquo;времени жизни&raquo; конкретного файла и&nbsp;настройки вашего браузера).</p>
      <p>&nbsp;</p>
      <h2>Cookie файлы бывают различных типов:</h2>
      <p>&nbsp;</p>
      <p><strong>Необходимые.&nbsp;</strong>Эти файлы нужны для обеспечения правильной работы сайта, использования его функций. Отключение использования таких файлов приведет к&nbsp;падению производительности сайта, невозможности использовать его компоненты и&nbsp;сервисы.</p>
      <p><strong>Файлы cookie, относящиеся к&nbsp;производительности, эффективности и&nbsp;аналитике.&nbsp;</strong>Данные файлы позволяют анализировать взаимодействие посетителей с&nbsp;сайтом, оптимизировать содержание сайта, измерять эффективность рекламных кампаний, предоставляя информацию о&nbsp;количестве посетителей сайта, времени его использования, возникающих ошибках.</p>
      <p><strong>Функциональные файлы cookie&nbsp;</strong>запоминают пользователей, которые уже заходили на&nbsp;наш сайт, их&nbsp;индивидуальные параметры (такие как язык и&nbsp;регион, например) и&nbsp;предпочтения, и&nbsp;помогают индивидуализировать содержание сайта.</p>
      <p><strong>Рекламные файлы cookie&nbsp;</strong>определяют, какие сайты Вы&nbsp;посещали и&nbsp;как часто, какие ссылки Вы&nbsp;выбирали, что позволяет показывать Вам рекламные объявления, которые заинтересуют именно Вас.</p>
      <p><strong>Электронная почта.&nbsp;</strong>Мы&nbsp;также можем использовать технологии, позволяющие отслеживать, открывали&nbsp;ли вы, прочитали или переадресовывали определенные сообщения, отправленные нами на&nbsp;вашу электронную почту. Это необходимо, чтобы сделать наши средства коммуникации более полезными для пользователя. Если вы&nbsp;не&nbsp;желаете, чтобы мы&nbsp;получали сведения об&nbsp;этом, вам нужно аннулировать подписку посредством ссылки &laquo;Отписаться&raquo; (&laquo;Unsubscribe&raquo;), находящейся внизу соответствующей электронной рассылки.</p>
      <p><strong>Кнопки доступа к&nbsp;социальным сетям.&nbsp;</strong>Они используются для того, чтобы пользователи могли поделиться ссылкой на&nbsp;страницу в&nbsp;социальных сетях или сделать электронную закладку. Данные кнопки являются ссылками на&nbsp;веб-сайты социальных сетей, принадлежащих третьим лицам, которые, в&nbsp;свою, очередь могут фиксировать информацию о&nbsp;вашей активности в&nbsp;интернете, в&nbsp;том числе на&nbsp;нашем сайте. Пожалуйста, ознакомьтесь с&nbsp;соответствующими условиями использования и&nbsp;политикой конфиденциальности таких сайтов для понимания того, как они используют ваши данные, и&nbsp;того, как можно отказаться от&nbsp;использования ими ваших данных или удалить их.</p>
      <p><strong>Сторонние веб-сервисы.&nbsp;</strong>Иногда на&nbsp;данном сайте мы&nbsp;используем сторонние веб-сервисы. Например, для отображения тех или иных элементов (изображения, видео, презентации и&nbsp;т.&nbsp;п.), организации опросов и&nbsp;т.&nbsp;п. Как и&nbsp;в&nbsp;случае с&nbsp;кнопками доступа к&nbsp;социальным сетям, мы&nbsp;не&nbsp;можем препятствовать сбору этими сайтами или внешними доменами информации о&nbsp;том, как вы&nbsp;используете содержание сайта.</p>
      <p>&nbsp;</p>
      <h2>Как управлять файлами cookie?</h2>
      <p>&nbsp;</p>
      <p>Большинство интернет-браузеров изначально настроены на&nbsp;автоматический прием файлов cookie.</p>
      <p>В&nbsp;любое время Вы&nbsp;можете изменить настройки вашего браузера таким образом, чтобы блокировать файлы cookie или предупреждать вас о&nbsp;том, когда они будут отправляться к&nbsp;вам на&nbsp;устройство (обратитесь к&nbsp;руководству использования конкретного браузера). Отключение файлов cookie может повлиять на&nbsp;Вашу работу в&nbsp;интернете.</p>
      <p>Если вы&nbsp;используете несколько устройств и&nbsp;(или) браузеров для доступа в&nbsp;интернет, соответствующие настройки должны быть изменены в&nbsp;каждом из&nbsp;них.</p>
      <p>&nbsp;</p>
      <h2>Заключительные положения</h2>
      <p>&nbsp;</p>
      <p>По&nbsp;собственному усмотрению мы&nbsp;можем периодически изменять настоящее Уведомление.</p>
      <p>По&nbsp;возникающим вопросам с&nbsp;нами можно связаться, используя контакты, размещенные на&nbsp;нашем сайте.</p>
    </div>
  )
}

export default Cookie
